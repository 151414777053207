'use client';

import { useRef, useImperativeHandle, forwardRef } from 'react';

const Icon = forwardRef(({ name, className, ...props }, ref) => {
	//! Ref
	const iconRef = useRef();

	//! Imperative Handle
	useImperativeHandle(ref, () => [iconRef.current], []);

	return (
		<i
			ref={iconRef}
			onClick={props.onClick || null}
			className={`ice-${name} ${className || ''}`}>
			{props.children}
		</i>
	);
});

Icon.displayName = 'Icon';

export default Icon;
