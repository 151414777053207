import styled from 'styled-components';

const NewsContentStyle = styled.section`
	--titleDescriptionDistance: var(--sp6x);
	--distanceTitleContent: var(--sp6x);

	--imagePadT: var(--sp3x);
	--imagePadLR: var(--sp3x);
	--radiusCard: var(--sp5x);
	--radiusImage: var(--sp3x);
	--sizeImage: 74%;

	--infoPadLR: var(--sp6x);
	--infoPadB: var(--sp6x);
	--distanceSubtitleDate: var(--sp6x);
	--distanceSubtitleImage: var(--sp5x);

	overflow: hidden;

	.news-wrapper-button-title {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: var(--distanceTitleContent);

		.news-title {
			margin-bottom: var(--titleDescriptionDistance);
		}
		.wrapper-button {
			display: flex;
			justify-content: flex-end;
		}
	}

	.wrapper-news {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: var(--gutter);

		.item-news-wrapper {
			border-radius: var(--radiusCard);
			position: relative;
			overflow: hidden;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				background-color: var(--white);
				transition: all var(--trTime) ease-out;
				/* opacity: 0.25; */
			}

			.link-item-card {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		}
		.image-wrapper {
			padding: var(--imagePadT) var(--imagePadLR) 0;

			.image-cont {
				--proportion: var(--sizeImage);

				border-radius: var(--radiusImage);
				overflow: hidden;
			}
		}

		.info-wrapper {
			padding: 0 var(--infoPadLR);
			padding-bottom: var(--infoPadB);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			.subtitle {
				margin-bottom: var(--distanceSubtitleDate);
				margin-top: var(--distanceSubtitleImage);
			}
		}
	}

	@media (hover: hover) {
		.wrapper-news {
			.item-news-wrapper {
				&:hover {
					&:after {
						background-color: var(--lightBlue);
						opacity: 0.25;
					}
				}
			}
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--titleDescriptionDistance: var(--sp5x);
		--distanceTitleContent: var(--sp5x);

		--imagePadT: var(--sp2x);
		--imagePadLR: var(--sp2x);
		--radiusImage: var(--sp3x);
		--radiusCard: var(--sp4x);
		--sizeImage: 74.7%;

		--infoPadLR: var(--sp5x);
		--infoPadB: var(--sp5x);
		--distanceSubtitleDate: var(--sp5x);
		--distanceSubtitleImage: var(--sp4x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--titleDescriptionDistance: var(--sp4x);
		--distanceTitleContent: var(--sp4x);

		--imagePadT: var(--sp2x);
		--imagePadLR: var(--sp2x);
		--radiusImage: var(--sp2-5x);
		--radiusCard: var(--sp3x);
		--sizeImage: 84.2%;

		--infoPadLR: var(--sp4x);
		--infoPadB: var(--sp4x);
		--distanceSubtitleDate: var(--sp4x);
		--distanceSubtitleImage: var(--sp4x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--titleDescriptionDistance: var(--sp3x);
		--distanceTitleContent: var(--sp3x);

		--imagePadT: var(--sp2x);
		--imagePadLR: var(--sp2x);
		--radiusImage: var(--sp2x);
		--radiusCard: var(--sp2-5x);
		--sizeImage: 74.7%;

		--infoPadLR: var(--sp3x);
		--infoPadB: var(--sp3x);
		--distanceSubtitleDate: var(--sp3x);
		--distanceSubtitleImage: var(--sp3x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--titleDescriptionDistance: var(--sp3x);
		--distanceTitleContent: var(--sp3x);

		--imagePadT: var(--sp1x);
		--imagePadLR: var(--sp1x);
		--radiusImage: var(--sp2x);
		--radiusCard: var(--sp2x);
		--sizeImage: 76%;

		--infoPadLR: var(--sp2-5x);
		--infoPadB: var(--sp3x);
		--distanceSubtitleDate: var(--sp2-5x);
		--distanceSubtitleImage: var(--sp2-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--titleDescriptionDistance: var(--sp3x);
		--distanceTitleContent: var(--sp3x);

		--imagePadT: var(--sp1x);
		--imagePadLR: var(--sp1x);
		--radiusImage: var(--sp1x);
		--radiusCard: var(--sp2x);
		--sizeImage: 75.5%;

		--infoPadLR: var(--sp2x);
		--infoPadB: var(--sp2x);
		--distanceSubtitleDate: var(--sp2x);
		--distanceSubtitleImage: var(--sp2x);

		.item-news-2 {
			display: none;
		}

		.wrapper-news {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--titleDescriptionDistance: var(--sp2x);
		--distanceTitleContent: var(--sp3x);

		--imagePadT: var(--sp1x);
		--imagePadLR: var(--sp1x);
		--radiusImage: var(--sp1x);
		--radiusCard: var(--sp2x);
		--sizeImage: 76.8%;

		--infoPadLR: var(--sp2x);
		--infoPadB: var(--sp3x);
		--distanceSubtitleDate: var(--sp4x);
		--distanceSubtitleImage: var(--sp3x);

		.item-news-2 {
			display: block;
		}

		.news-wrapper-button-title {
			grid-template-columns: auto;
			grid-template-rows: 1fr;
			gap: var(--sp3x);

			.wrapper-button {
				justify-content: flex-start;
			}
		}

		.wrapper-news {
			grid-template-columns: 1fr;
			gap: var(--sp2x);
		}
	}
`;

export default NewsContentStyle;
