import { useEffect, useMemo, useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap/all';

//* Helpers
import { config } from '@/helpers';

//* Style
import NewsContentStyle from './style';

//* HOO'C
import { withUIContext } from '@/context';

//* Components
import Text from '@/components/Text';
import Image from '@/components/Image';
import Button from '@/components/Button';
import Container from '@/components/Container';
import CustomLink from '@/components/CustomLink';
import Resize from '@/components/Resize';

const NewsContent = ({ data, title, description, reverseAnimation, winWidth }) => {
	data = data.slice(0, 3);

	//! State
	const [resize, setResize] = useState(false);

	//! Ref
	const wrapperNewsRef = useRef(null);
	const triggerRef = useRef(null);
	const firstLoad = useRef(true);

	//! Animate
	useEffect(() => {
		if (wrapperNewsRef.current && data.length && firstLoad.current) {
			if (reverseAnimation) {
				[...wrapperNewsRef.current.children].forEach((item, i) => {
					gsap.set(item, { y: `${winWidth < 768 ? ((i % 3) + 1) * 40 : (4 - (i % 3)) * 40}%`, duration: 0 });
				});
			} else {
				[...wrapperNewsRef.current.children].forEach((item, i) => {
					gsap.set(item, { y: `${((i % 3) + 1) * 40}%`, duration: 0 });
				});
			}

			gsap.to(wrapperNewsRef.current, { opacity: 1, duration: 0 });

			triggerRef.current = ScrollTrigger.create({
				start: 'top 70%',
				end: 'top top',
				trigger: wrapperNewsRef.current,
				// markers: true,
				once: true,
				onEnter: () => {
					firstLoad.current = false;
					if (reverseAnimation) {
						gsap.to([...wrapperNewsRef.current.children].reverse(), { y: 0, duration: 0.6, stagger: 0.05 });
					} else {
						gsap.to([...wrapperNewsRef.current.children], { y: 0, duration: 0.6, stagger: 0.05 });
					}
				},
			});
		}
		return () => {
			if (triggerRef.current) {
				triggerRef.current.kill();
				triggerRef.current = null;
			}
		};
	}, [resize, data]);

	const controlData = useMemo(() => {
		return data?.map((item, i) => {
			return (
				<div
					key={item.id}
					className={`item-news-wrapper item-news-${i}`}>
					<CustomLink
						className={'link-item-card'}
						url={`${config.routes.news.path}/${item.slug}`}>
						<div className='image-wrapper'>
							<Image
								src={item.featured_image.src}
								alt={item.featured_image.alt}
							/>
						</div>
						<div className='info-wrapper'>
							<Text className={'subtitle  p1 font-montserrat-arm-medium'}>{item.featured_text}</Text>
							<div className='wrapper-date'>
								<Text className={'data  font-montserrat-arm-medium p3 date-text'}>{item.date}</Text>
							</div>
						</div>
					</CustomLink>
				</div>
			);
		});
	}, [data]);

	return (
		<NewsContentStyle>
			<Resize setResize={setResize}>
				<Container
					isSection
					row>
					<div className={'wrapper-container'}>
						<div className='news-wrapper-button-title'>
							<div className='wrapper-title-description'>
								<Text
									tag={'h2'}
									className={'uppercase h2 font-montserrat-arm-medium blue-1000 news-title'}>
									{title}
								</Text>
								<Text className={'p1  blue-1000 news-description'}>{description}</Text>
							</div>
							<div className='wrapper-button'>
								<Button
									url={config.routes.news.path}
									text={'seeMore'}
									className={'secondary p2 blue-900 button-news'}
								/>
							</div>
						</div>

						<div
							ref={wrapperNewsRef}
							className={'wrapper-news opacity-0'}>
							{controlData}
						</div>
					</div>
				</Container>
			</Resize>
		</NewsContentStyle>
	);
};

export default withUIContext(NewsContent, ['winWidth']);
