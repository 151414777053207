import styled from 'styled-components';

const ButtonStyle = styled.div`
	--padTB: var(--sp2x);
	--padLR: var(--sp4x);
	--lottiePadTB: var(--sp1x);
	--lottiePadLR: var(--sp2x);
	--radiusBorder: var(--sp5x);
	--lottieMaxWidth: 20px;
	--lottieDownload: var(--sp4x);
	--distanceDownload: var(--sp1-5x);

	width: fit-content;
	height: fit-content;

	&.all-button {
		button,
		a {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			text-align: center;
			font-size: var(--p2);
			padding: var(--padTB) var(--padLR);
			font-family: var(--montserrat_arm);
			font-weight: 500;
			line-height: var(--lineHeightL);
			color: var(--blue1000);
			background: unset;
			cursor: pointer;
			background-color: var(--white);
			border-radius: var(--radiusBorder);
			transition: var(--trTime) ease-out;
			border: 2px solid var(--white);
		}
	}

	&.primary {
		a,
		button {
			background-color: var(--blue1000);
			border: 2px solid var(--blue1000);
			color: var(--white);

			i {
				&:before {
					color: var(--white);
				}
			}
		}

		@media (hover: hover) {
			&:hover {
				a,
				button {
					color: var(--white);
					background-color: transparent;
					border: 2px solid var(--white);

					i {
						&::before {
							color: var(--blue1000);
						}
					}
				}
			}
		}
	}

	&.secondary {
		a,
		button {
			background-color: var(--white);
			border: 2px solid var(--white);
			color: var(--blue1000);

			i {
				&:before {
					color: var(--blue1000);
				}
			}
		}

		@media (hover: hover) {
			&:hover {
				a,
				button {
					color: var(--blue1000);
					background-color: transparent;
					border: 2px solid var(--blue1000);

					i {
						&::before {
							color: var(--blue1000);
						}
					}
				}

				&.reverse {
					a,
					button {
						color: var(--white);
						border: 2px solid var(--white);
					}
				}
			}
		}
	}

	&.filter {
		a,
		button {
			background-color: transparent;
			border: 2px solid var(--blue1000);
			color: var(--blue1000);

			i {
				&:before {
					color: var(--blue1000);
				}
			}
		}

		&.active {
			pointer-events: none;
			cursor: default;
			a,
			button {
				color: var(--white);
				border: 2px solid var(--blue1000);
				background-color: var(--blue1000);
			}
		}

		@media (hover: hover) {
			&:hover {
				a,
				button {
					color: var(--blue1000);
					background-color: var(--white);
					border: 2px solid var(--white);

					i {
						&::before {
							color: var(--blue1000);
						}
					}
				}
			}
		}
	}

	&.btn {
		&-lottie {
			width: fit-content;
			height: fit-content;

			.text-lottie {
				line-height: 1 !important;
			}

			button,
			a {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: var(--sp3x);
				background-color: var(--lightBlue);
				color: var(--white);
				padding: var(--lottiePadTB) var(--lottiePadLR);
				cursor: pointer;

				.lottie-wrap {
					width: var(--lottieMaxWidth);
					display: flex;
					align-items: flex-end;
				}

				&:hover {
					background-color: var(--lightBlue);
				}
			}

			&.arrow {
				svg {
					max-width: var(--lottieMaxWidth);
				}

				button,
				a {
					gap: var(--distanceArrow);
					align-items: flex-end;
					border-radius: unset;
					background-color: var(--lightBlue);
				}

				p {
					font-family: var(--montserrat_arm);
					font-weight: 400;
				}
			}

			&.download {
				p {
					font-size: var(--p5);
					color: var(--white);
					font-family: var(--montserrat_arm);
					font-weight: 400;
				}

				svg {
					max-width: var(--lottieDownload);
				}

				button,
				a {
					align-items: center;
					gap: var(--distanceDownload);
					color: var(--white);
				}
			}
		}
	}

	&.disabled {
		button {
			pointer-events: none;
			cursor: not-allowed;
			color: var(--grayInput);
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--radiusBorder: var(--sp4x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--radiusBorder: var(--sp3x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--padTB: var(--sp1-5x);
		--padLR: var(--sp3-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--padTB: var(--sp1-5x);
		--padLR: var(--sp3x);
		--lottiePadLR: var(--sp1-5x);
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
	}
`;

export default ButtonStyle;
