'use client';

import { useEffect, useState } from 'react';

//* HOC's
import { withDataContext, withLanguageContext } from '@/context';

//* Libs
import { getPage } from '@/libs/get-data';

//* Components
import Content from './Content';

const NewsPosts = ({ selectedLang, globalData, reverseAnimation }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		getPage({ slug: 'posts', lang: selectedLang, params: { limit: 3 } }).then((data) => {
			setData(data || []);
		});
	}, [selectedLang]);

	return (
		<Content
			title={globalData.news.title}
			description={globalData.news.description}
			data={data.posts || []}
			reverseAnimation={reverseAnimation}
		/>
	);
};

export default withDataContext(withLanguageContext(NewsPosts, ['selectedLang']), ['globalData']);
