import { useMemo, useState } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Components
import Text from '../Text';
import CustomLink from '../CustomLink';
import LottieAnimation from '../LottieAnimation';

//* Style
import ButtonStyle from './style';

const Button = ({ lottieName, lottieFiles, btnType, text, className, onClick, url, translate, type = 'button', disabled = false, children, external = false, ariaLabel }) => {
	//! State
	const [arrowLottie, setArrowLottie] = useState(false);

	const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);
	const customProps = useMemo(() => (url ? { url, external } : { type, disabled }), [url, external, type, disabled]);

	let btn;

	switch (btnType) {
		case 'primary':
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					className={`btn primary all-button ${className || ''} ${disabled ? 'disabled' : ''}`}>
					<Component {...customProps}>{children || translate(text)}</Component>
				</ButtonStyle>
			);

			break;

		case 'secondary':
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					className={`btn secondary all-button ${className || ''} ${disabled ? 'disabled' : ''}`}>
					<Component {...customProps}>{children || translate(text)}</Component>
				</ButtonStyle>
			);

			break;

		case 'lottie':
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					onMouseEnter={() => setArrowLottie(true)}
					onMouseLeave={() => setArrowLottie(false)}
					className={`btn-lottie all-button ${className || ''} ${lottieName || ''} ${disabled ? 'disabled' : ''}`}>
					<Component {...customProps}>
						{children || text ? <Text className={'white text-lottie'}>{children || translate(text)}</Text> : null}
						<LottieAnimation
							key={arrowLottie}
							autoplay={false}
							isPlay={arrowLottie}
							animData={lottieFiles}
						/>
					</Component>
				</ButtonStyle>
			);
			break;

		case 'default':
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					className={`${className || ''} ${disabled ? 'disabled' : ''}`}>
					<Component
						{...ariaLabel}
						{...customProps}>
						{children || translate(text)}
					</Component>
				</ButtonStyle>
			);
			break;

		default:
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					className={`btn all-button ${className || ''} ${disabled ? 'disabled' : ''}`}>
					<Component
						{...ariaLabel}
						{...customProps}>
						{children || translate(text)}
					</Component>
				</ButtonStyle>
			);
	}

	return btn;
};

export default withLanguageContext(Button, ['translate']);
