'use client';

import gsap from 'gsap';
import { ScrollTrigger, SplitText } from 'gsap/all';
import { useGSAP } from '@gsap/react';

//* HOC's
import { withLanguageContext } from '@/context';

const ScrollActive = ({ dependencies, type, overflow, selectedLang, marker, animTitle, allRefs, stagger, reverse, once, duration, delay, paramEnded, paramStarted, isParamStart, isParam, percentDuration, percent, percentFunction, children }) => {
	//! Plugins
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(SplitText);

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger);

		//! Animation title word up
		//default style in react opacity-0
		if (animTitle) {
			const defaultConfig = {
				duration: duration == 0 ? 0 : duration || 0.5,
				stagger: stagger == 0 ? 0 : stagger || 0.1,
				once: true,
				delay: delay == 0 ? 0 : delay || 0,
				type: type || 'lines',
			};

			ScrollTrigger.create({
				trigger: allRefs.current,
				start: `${marker || 'center'} 90%`,
				end: `${marker || 'center'} top`,
				// markers: true,
				once: once || defaultConfig.once,

				onEnter: () => {
					const mySplitText = new SplitText(allRefs.current, { type: `${defaultConfig.type} lines` });

					if (overflow) {
						const splitNews = new SplitText(allRefs.current, { type: `${defaultConfig.type} lines`, linesClass: 'line-class' });
						const allLines = allRefs.current.querySelectorAll('.line-class');
						allLines.forEach((item) => {
							item.style.overflow = 'hidden';
						});
					}
					let getPercent = (mySplitText[defaultConfig.type].length - 1) * defaultConfig.stagger + defaultConfig.duration + defaultConfig.delay;
					if (percentDuration) {
						getPercent = (percentDuration * getPercent) / 100;
					} else {
						getPercent = (75 * getPercent) / 100;
					}

					gsap.to(allRefs.current, {
						opacity: 1,
						duration: 0,
					}).then(() => {
						if (isParamStart) {
							paramStarted(true);
						}
						if (percent) {
							percentFunction(getPercent);
						}
						gsap.set(mySplitText[defaultConfig.type], { y: reverse ? -100 : 100 });
						gsap.set(mySplitText.lines, { overflow: 'hidden' });
						gsap.to(mySplitText[defaultConfig.type], {
							y: 0,
							ease: 'power2.out',
							duration: defaultConfig.duration,
							stagger: defaultConfig.stagger,
							delay: defaultConfig.delay,
						}).then(() => {
							if (isParam) {
								paramEnded(true);
							}
						});
					});
				},
			});
		}
	}, [selectedLang, dependencies]);

	return children;
};

export default withLanguageContext(ScrollActive, ['selectedLang']);
