import { cache } from 'react';
import { notFound } from 'next/navigation';

//* Helpers
import api from '@/helpers/_api';

export const revalidate = 3600;

export const getGlobal = cache(async ({ lang }) => {
	const data = await api.get({ url: 'global-data', lang });

	return data;
});

export const getPage = cache(async ({ slug, lang, params = {} }) => {
	return await api.get({ url: `${slug}`, lang, params }).catch((err) => {
		notFound();
	});
});

//! Post Categories
export const getPostCategories = cache(async ({ lang, params = {} }) => {
	return await api.get({ url: 'post-categories', lang, params });
});

//! Reform Categories
export const getReformCategories = cache(async ({ lang, params = {} }) => {
	return await api.get({ url: 'reform-categories', lang, params });
});

//! Organizations
export const getOrganizations = cache(async ({ lang, params = {} }) => {
	return await api.get({ url: 'organizations', lang, params });
});

//! Related Posts
export const getRelatedPosts = cache(async ({ currentPostId, postCategoryId, reformCategoryId, lang }) => {
	const { data } = await api.get({
		url: 'related-posts',
		lang,
		params: {
			currentPostId,
			postCategoryId,
			reformCategoryId,
		},
	});

	return data;
});

//! Meta
export const getMeta = cache(async ({ slug, lang }) => {
	return await api.get({ url: `seo/${slug}`, lang }).catch((err) => {
		redirect('/not-found');
	});
});
