import styled from 'styled-components';

const ReformContentStyle = styled.section`
	--titleDescriptionDistance: var(--sp6x);
	--distanceTitleContent: var(--sp6x);
	--radiusItem: var(--sp5x);
	--sizeImage: 122.42%;
	--otherPadInfo: var(--sp3x);
	--innerPadding: var(--sp3x);
	--innerContentRadius: var(--sp3x);
	--distanceSubtitleInfo: var(--sp8x);
	--statusPadTB: var(--sp1x);
	--statusPadLR: var(--sp2x);
	--statusRadius: var(--sp4x);
	--distanceDate: var(--sp1x);

	overflow: hidden;

	.reform-wrapper-button-title {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: var(--distanceTitleContent);

		.reform-title {
			margin-bottom: var(--titleDescriptionDistance);
		}
		.wrapper-button {
			display: flex;
			justify-content: flex-end;
		}
	}

	.wrapper-reform {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: var(--gutter);

		.item-reform-wrapper {
			position: relative;
			border-radius: var(--radiusItem);
			overflow: hidden;

			.image-cont {
				--proportion: var(--sizeImage);
			}

			.info-wrapper {
				width: 100%;
				position: absolute;
				bottom: 0;
				padding: var(--otherPadInfo);
			}
		}
	}

	.link-item-card {
		position: relative;
		z-index: 1;
		top: 0;
		left: 0;
		padding: var(--innerPadding);
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.subtitle,
		.date-text,
		&:after,
		.status-text {
			transition: var(--trTime) ease-out;
			color: var(--white);
		}

		.subtitle {
			margin-bottom: var(--distanceSubtitleInfo);
		}

		.status-text {
			width: fit-content;
			padding: var(--statusPadTB) var(--statusPadLR);
			background-color: var(--white);
			border-radius: var(--statusRadius);
			color: var(--blue900);
		}

		.wrapper-date-status {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.date-wrapper {
			display: flex;
			gap: var(--distanceDate);
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background-color: var(--blue900);
			opacity: 0.9;
			border-radius: var(--innerContentRadius);
		}
	}

	@media (hover: hover) {
		.link-item-card {
			&:hover {
				&:after {
					background-color: var(--white);
				}

				.subtitle,
				.date-text {
					color: var(--blue900);
				}

				.status-text {
					color: var(--white);
					background-color: var(--lightBlue);
				}
			}
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--titleDescriptionDistance: var(--sp5x);
		--distanceTitleContent: var(--sp5x);
		--radiusItem: var(--sp3x);
		--sizeImage: 123.3%;
		--otherPadInfo: var(--sp2x);
		--innerPadding: var(--sp3x);
		--innerContentRadius: var(--sp3x);
		--distanceSubtitleInfo: var(--sp5x);
		--statusPadTB: var(--sp1x);
		--statusPadLR: var(--sp2x);
		--statusRadius: var(--sp4x);
		--distanceDate: var(--sp0-5x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--titleDescriptionDistance: var(--sp4x);
		--distanceTitleContent: var(--sp4x);
		--radiusItem: var(--sp3x);
		--sizeImage: 133.2%;
		--otherPadInfo: var(--sp2x);
		--innerPadding: var(--sp2-5x);
		--innerContentRadius: var(--sp3x);
		--distanceSubtitleInfo: var(--sp8x);
		--statusPadTB: var(--sp1x);
		--statusPadLR: var(--sp2x);
		--statusRadius: var(--sp4x);
		--distanceDate: var(--sp0-5x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--titleDescriptionDistance: var(--sp3x);
		--distanceTitleContent: var(--sp3x);
		--radiusItem: var(--sp2-5x);
		--sizeImage: 129%;
		--otherPadInfo: var(--sp1x);
		--innerPadding: var(--sp2x);
		--innerContentRadius: var(--sp2x);
		--distanceSubtitleInfo: var(--sp3x);
		--statusPadTB: var(--sp1x);
		--statusPadLR: var(--sp2x);
		--statusRadius: var(--sp4x);
		--distanceDate: var(--sp0-5x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--titleDescriptionDistance: var(--sp3x);
		--distanceTitleContent: var(--sp3x);
		--radiusItem: var(--sp2x);
		--sizeImage: 128.7%;
		--otherPadInfo: var(--sp1x);
		--innerPadding: var(--sp2x);
		--innerContentRadius: var(--sp2x);
		--distanceSubtitleInfo: var(--sp2x);
		--statusPadTB: var(--sp1x);
		--statusPadLR: var(--sp2x);
		--statusRadius: var(--sp4x);
		--distanceDate: var(--sp0-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--titleDescriptionDistance: var(--sp3x);
		--distanceTitleContent: var(--sp3x);
		--radiusItem: var(--sp2x);
		--sizeImage: 128.7%;
		--otherPadInfo: var(--sp1x);
		--innerPadding: var(--sp1x);
		--innerContentRadius: var(--sp1x);
		--distanceSubtitleInfo: var(--sp2x);
		--statusPadTB: var(--sp1x);
		--statusPadLR: var(--sp1-5x);
		--statusRadius: var(--sp3x);
		--distanceDate: var(--sp0-5x);

		.item-reform-2 {
			display: none;
		}

		.wrapper-reform {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--titleDescriptionDistance: var(--sp2x);
		--distanceTitleContent: var(--sp3x);
		--radiusItem: var(--sp2x);
		--sizeImage: 131%;
		--otherPadInfo: var(--sp2x);
		--innerPadding: var(--sp1x);
		--innerContentRadius: var(--sp1x);
		--distanceSubtitleInfo: var(--sp2x);
		--statusPadTB: var(--sp1x);
		--statusPadLR: var(--sp1-5x);
		--statusRadius: var(--sp3x);
		--distanceDate: var(--sp0-5x);

		.item-reform-2 {
			display: block;
		}

		.reform-wrapper-button-title {
			grid-template-columns: auto;
			grid-template-rows: 1fr;
			gap: var(--sp3x);

			.wrapper-button {
				justify-content: flex-start;
			}
		}

		.wrapper-reform {
			grid-template-columns: 1fr;
			gap: var(--sp1x);
		}
	}
`;

export default ReformContentStyle;
